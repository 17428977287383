var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        directives: [{ name: "fullscreen", rawName: "v-fullscreen" }],
        attrs: {
          visible: _vm.dialogVisible,
          width: "40%",
          top: "10vh",
          "custom-class": "contract-type",
          "close-on-click-modal": false,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "base-title-font",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("em", {
            staticClass: "iconfont icon-yuandianzhong",
            style: { color: _vm.$store.state.currentTheme.primaryColor },
          }),
          _vm._v(" Add Terminal or Berth "),
        ]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "ruleForm",
              attrs: { model: _vm.locationFormData },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Terminal",
                    prop: "parentId",
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", "value-key": "id" },
                      model: {
                        value: _vm.locationFormData.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.locationFormData, "parentId", $$v)
                        },
                        expression: "locationFormData.parentId",
                      },
                    },
                    _vm._l(_vm.locationParentOptionList, function (terminal) {
                      return _c("el-option", {
                        key: terminal.id,
                        attrs: { label: terminal.name, value: terminal.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Name",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "This field is required",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.locationFormData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.locationFormData, "name", $$v)
                      },
                      expression: "locationFormData.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "meduim", round: "" },
              on: { click: _vm.handleClose },
            },
            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "meduim",
                round: "",
                type: "primary",
                loading: _vm.loading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }